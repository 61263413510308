// extracted by mini-css-extract-plugin
export var errorModalPopup = "activation-form-module--errorModalPopup--f06db";
export var errorMsg = "activation-form-module--errorMsg--eb517";
export var formGroup = "activation-form-module--formGroup--0cce8";
export var headerBar = "activation-form-module--headerBar--2edc7";
export var mainRow = "activation-form-module--mainRow--def21";
export var modalAcceptButton = "activation-form-module--modal-accept-button--98ff6";
export var phoneInput = "activation-form-module--phoneInput--67cdd";
export var phoneSearchBarWrapper = "activation-form-module--phoneSearchBarWrapper--3d502";
export var searchBarWrapper = "activation-form-module--searchBarWrapper--2fb95";
export var select = "activation-form-module--select--5170a";
export var selectPlaceholderText = "activation-form-module--selectPlaceholderText--8782d";
export var subTitle = "activation-form-module--subTitle--f0c74";
export var title = "activation-form-module--title--c39ff";