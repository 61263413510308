import styled from 'styled-components';

export const CustomRadioContainer = styled.label`
  display: block;

  position: relative;

  padding-left: 12px;

  font-family: 'Montserrat Regular' !important;

  cursor: pointer;

  font-size: 14px;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
  @media (max-width: 600px) {
    padding-left: 20px;
  }

  input {
    position: absolute;

    opacity: 0;

    cursor: pointer;

    height: 0;

    width: 0;
  }

  color: #4d4d4d !important;

  span {
    color: #4d4d4d;
  }

  label {
    color: #4d4d4d;
  }

  .radio-checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    @media (max-width: 600px) {
      height: 24px;
      width: 24px;
      top: 2px;
    }
  }

  /* On mouse-over, add a grey background color */
  input ~ .radio-checkmark {
    background-color: white;
    border: 1px solid #29435a;
    opacity: 0.5;
  }

  input:disabled ~ .radio-checkmark {
    background-color: #ddd;
    border: 1px solid #ddd;
    opacity: 1;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .radio-checkmark {
    background-color: #fff;
    border: 4px solid #7f9dc6;
    opacity: 1;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .radio-checkmark:after {
    display: none;
  }

  /* Style the indicator (dot/circle) */
  .radio-checkmark:after {
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #29435a;
  }
`;

export const RadioButton = styled.label`
  display: block;
  position: relative;
  padding-left: 10px;
  /* margin-bottom: 0px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 33px;
    width: 0;
  }
  @media (max-width: 600px) {
    padding-left: 16px;
  }

  .radio-checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 4px;
    @media (max-width: 600px) {
      top: 0px;
      height: 24px;
      width: 24px;
    }
  }

  /* On mouse-over, add a grey background color */
  input ~ .radio-checkmark {
    background-color: white;
    border: 1px solid #29435a;
    opacity: 0.5;
  }

  input:disabled ~ .radio-checkmark {
    background-color: #ddd;
    border: 1px solid #ddd;
    opacity: 1;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .radio-checkmark {
    background-color: #7f9dc6;
    border: 1px solid #7f9dc6;
    opacity: 1;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .radio-checkmark:after {
    display: block;
    left: ${(props) => (props.afterMarkLeft ? `${props.afterMarkLeft}px` : '4px')};
    top: 0px;
    width: ${(props) => (props.afterMarkWidth ? `${props.afterMarkWidth}px` : '6px')};
    height: ${(props) => (props.afterMarkHeight ? `${props.afterMarkHeight}px` : '13px')};
    opacity: 0.99 !important;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    @media (max-width: 600px) {
      left: 7px;
      width: 7px;
      top: 2px;
    }
  }
`;

export const Label = styled.div`
  color: #4c4c4c;
  font-family: 'Montserrat Regular';
  font-size: 14px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  width: 128px;
  min-width: ${(props) => props.width || '128'}px;
  height: ${(props) => props.height || '33'}px;
  outline: none;
  font-size: 14px;
  background-color: ${(props) =>
    props.disabled && props.primary
      ? '#a1adbe'
      : props.disabled && props.secondary
        ? '#f6f6f6'
        : props.primary
          ? '#3f5c83'
          : '#fff'};
  color: ${(props) =>
    props.disabled && props.primary
      ? '#fff'
      : props.disabled && props.secondary
        ? '#c6c6c6'
        : props.primary
          ? '#fff'
          : '#3f5c83'};
  border: 2px solid
    ${(props) =>
    props.disabled && props.primary
      ? '#a1adbe'
      : props.disabled && props.secondary
        ? '#f6f6f6'
        : props.border || props.primary
          ? '#3f5c83'
          : '#F9F7F9'};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-align: center;
  display: flex;
  // text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-family: Montserrat Medium;

  &:hover {
    background-color: ${(props) =>
    props.disabled && props.primary
      ? '#a1adbe'
      : props.disabled && props.secondary
        ? '#f6f6f6'
        : props.primary
          ? '#7f9dc6'
          : '#edebed'};
    border: 2px solid
      ${(props) =>
    props.disabled && props.primary
      ? '#a1adbe'
      : props.disabled && props.secondary
        ? '#f6f6f6'
        : props.border || props.primary
          ? '#7f9dc6'
          : '#edebed'};
  }
  cursor: ${(props) => (props.disabled ? 'default !important' : 'pointer')};

  @media (max-width: 600px) {
    height: 48px;

    width: 100%;
    font-size: 16px;
  }
`;
