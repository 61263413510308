import { injectIntl } from 'gatsby-plugin-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { PageProps } from 'gatsby';
import SEO from '../components/ui/seo';
import DefaultLanguage from '../hoc/default-language';
import ActivationPage from '../components/activation-form';

const ActivationForm = ({ intl, location, pageContext }) => (
  <>
    <SEO page="activationForm" language={pageContext.language} />
    <DefaultLanguage location={location}>
      <ActivationPage intl={intl} language={pageContext.language} />
    </DefaultLanguage>
  </>
);

ActivationForm.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};
export default injectIntl(ActivationForm);
