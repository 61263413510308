import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useQuery } from 'react-query';
import Select, { components } from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import logoImg from '../../images/logo_white.svg';
import numuImg from '../../images/numu-icon.png';
import * as styles from '../../style/components/activation-form.module.scss';
import CategoryList from './categories-list';
import { Button, CustomRadioContainer, Label, RadioButton } from './style';

const ActivationPage = ({ intl, language }) => {
  const agenciesQuery = useQuery('agencies', async () => {
    const res = await fetch(`${process.env.GATSBY_NODE_API}/web/fetch-agencies?getAll=true`);
    return res.json();
  });
  const { data: categoriesData, error: categoryError } = useQuery('categories', async () => {
    const res = await fetch(`${process.env.GATSBY_NODE_API}/web/get-categories`);
    return res.json();
  });
  const { Option } = components;
  const mainClasses = [styles.mainRow, 'container'].join(' ');
  const IconOption = (props) => (
    <Option {...props}>
      <img
        alt=""
        src={props.data.logo || numuImg}
        style={{
          height: '30px',
          width: '30px',
          borderRadius: '50%',
          marginRight: '10px',
          objectFit: 'contain'
        }}
      />

      {props.data.label}
    </Option>
  );

  const [state, dispatch] = useReducer(
    (existingState, action) => ({
      ...existingState,
      ...action
    }),
    {
      hasInstagram: true,
      hasTiktok: true,
      instagramUserName: '',
      tiktokUserName: '',
      email: null,
      invalidEmail: false,
      phoneNumber: null,
      allowEmail: true,
      allowWhatsapp: true,
      hasAgency: null,
      agencyId: null,
      notificationError: false,
      agencyName: null,
      agencyEmail: null,
      agencyPhonenumber: '',
      agencyWebsite: null,
      subCategoryIds: [],
      dataRequiredError: false,
      success: false,
      loading: false,
      submitError: null,
      contactError: false,
      agencyEmailError: false,
      agencyContactError: false,
      noGift: false
    }
  );

  const showCategoriesList = () => (
    <div>
      {!categoryError &&
        categoriesData &&
        categoriesData.data &&
        categoriesData.data.map((item) => (
          <div key={item.name + item.id}>
            <CategoryList
              lang={language}
              category={language === 'en' ? item.name : item.es}
              subCategories={item.subCategories}
              categoryId={item.id}
              setData={(subCategoryIds) => {
                dispatch({ subCategoryIds });
              }}
              allCheckSubCategories={state.subCategoryIds}
            />
          </div>
        ))}
    </div>
  );

  const validNum = (number) => {
    const phoneno =
      /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([0-9][0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9])\s*)|([0-9]1[0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9]))\s*(?:[.-]\s*)?)([0-9]1[0-9]|[0-9][0-9]1|[0-9][0-9][0-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
    return number.match(phoneno);
  };

  const saveUserData = async () => {
    dispatch({ loading: true });
    const categoryData = categoriesData.data.reduce((total, cur) => {
      const selectedSubCategories = cur.subCategories.filter((subCategory) =>
        state.subCategoryIds.includes(subCategory.id)
      );
      const newTotal = [
        ...total,
        ...selectedSubCategories.map((subCategory) => ({
          subCategoryId: subCategory.id,
          categoryId: subCategory.categoryId
        }))
      ];
      return newTotal;
    }, []);
    const data = { ...state };
    delete data.invalidEmail;
    delete data.notificationError;
    delete data.subCategoryIds;
    delete data.dataRequiredError;
    delete data.success;
    delete data.loading;
    delete data.submitError;
    delete data.contactError;
    delete data.agencyEmailError;
    delete data.agencyContactError;

    data.instagramUserName = data.instagramUserName.trim();
    data.tiktokUserName = data.tiktokUserName.trim();

    if (data.hasInstagram && data.instagramUserName.startsWith('@')) {
      data.instagramUserName = data.instagramUserName.substring(1);
    }
    if (data.hasTiktok && data.tiktokUserName.startsWith('@')) {
      data.tiktokUserName = data.tiktokUserName.substring(1);
    }

    try {
      const response = await axios.post(`${process.env.GATSBY_NODE_API}/web/save-user-detail`, {
        ...data,
        hasAgency: state.hasAgency === 'yes',
        categoryData
      });
      if (response.status === 200) {
        dispatch({ loading: false, success: true });
      } else if (response.message) {
        dispatch({ submitError: response.message });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.data) {
        const { data: result } = response;
        if (result.data.noInsta) {
          dispatch({
            submitError: intl.formatMessage({
              id: `activation-form.noInstaUser`
            })
          });
        } else if (result.data.noTiktok) {
          dispatch({
            submitError: intl.formatMessage({
              id: `activation-form.noTiktokUser`
            })
          });
        }
      }
    } finally {
      dispatch({ loading: false });
    }
  };

  return (
    <div>
      <div className={styles.headerBar}>
        <img placeholder="blurred" src={logoImg} alt="numu_logo" />
      </div>
      {state.loading && (
        <div
          className={mainClasses}
          style={{
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <ClipLoader color="#3F5C83" loading={state.loading} width={10} size={100} />
        </div>
      )}
      {state.success && (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'column nowrap'
          }}>
          <p
            style={{
              color: '#445C80',
              fontSize: '28px',
              fontFamily: 'Montserrat SemiBold',
              textAlign: 'center'
            }}>
            {`${intl.formatMessage({
              id: `activation-form.successTitle`
            })}`}
          </p>
          <p
            style={{
              color: '#4D4D4D',
              fontSize: '21px',
              fontFamily: 'Montserrat Regular',
              padding: '0 60px',
              textAlign: 'center'
            }}>
            {`${intl.formatMessage({
              id: `activation-form.successSubtitle`
            })}`}
          </p>
        </div>
      )}

      {(state.submitError || state.dataRequiredError) && (
        <Modal
          // dialogClassName={breakpoints.md && styles.caseStudiespopup}
          className={styles.errorModalPopup}
          show={state.submitError || state.dataRequiredError}
          onHide={() => dispatch({ dataRequiredError: false, submitError: null })}>
          <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
            <p
              style={{
                color: '#445C80',
                fontSize: '28px',
                fontFamily: 'Montserrat SemiBold',
                textAlign: 'center',
                marginBottom: '32px',
                marginTop: '32px'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.requiredErrorTitle`
              })}`}
            </p>
            {state.submitError !== null ? (
              <p className={styles.errorMsg}>{state.submitError}</p>
            ) : (
              <p className={styles.errorMsg}>
                {`${intl.formatMessage({
                  id: `activation-form.requiredErrorSubtitle`
                })}`}
              </p>
            )}
            <Button
              primary
              style={{ minWidth: '128px', width: '128px', height: '33px', marginBottom: '32px' }}
              className="modal-accept-button"
              onClick={() => {
                dispatch({ dataRequiredError: false, submitError: null });
              }}>
              {`${intl.formatMessage({
                id: `activation-form.requiredErrorButton`
              })}`}
            </Button>
          </div>
        </Modal>
      )}
      {/*  Main Form */}
      {!state.dataRequiredError && !state.success && !state.loading && (
        <div style={{ fontFamily: 'Montserrat Regular' }} className={styles.mainRow}>
          <p className={styles.title}>
            {`${intl.formatMessage({
              id: `activation-form.title`
            })}`}
          </p>
          <p className={styles.subTitle}>
            {intl.formatMessage({
              id: `activation-form.subtitle`
            })}
          </p>
          <div style={{ marginTop: '64px' }}>
            <p
              style={{
                fontWeight: '500',
                color: '#4D4D4D',
                fontFamily: 'Montserrat Medium',
                marginBottom: '8px'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.instaUsername`
              })}`}
            </p>

            <div
              style={{ backgroundColor: !state.hasInstagram ? '#ddd' : 'transparent' }}
              className={styles.searchBarWrapper}>
              <input
                disabled={!state.hasInstagram}
                value={state.instagramUserName}
                onChange={(event) =>
                  dispatch({ instagramUserName: event.target.value.trim().toLowerCase() })
                }
                placeholder={
                  state.hasInstagram
                    ? `${intl.formatMessage({ id: `activation-form.required` })}`
                    : `${intl.formatMessage({ id: `activation-form.notRequired` })}`
                }
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', marginTop: 8 }}>
            <RadioButton htmlFor="sameEmail_1" style={{ display: 'flex' }}>
              <input
                style={{ borderRadius: 0, marginRight: 5 }}
                type="checkbox"
                id="sameEmail_1"
                checked={!state.hasInstagram}
                onChange={() => {
                  dispatch({ hasInstagram: !state.hasInstagram, instagramUserName: '' });
                }}
              />
              <Label
                style={{
                  marginLeft: 16,
                  marginTop: 2,
                  color: '#4d4d4d'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.instaMsg`
                })}`}
              </Label>
              <span className="radio-checkmark" />
            </RadioButton>
          </div>
          <div style={{ marginTop: '32px' }}>
            <p
              style={{
                color: '#4D4D4D',
                fontFamily: 'Montserrat Medium',
                marginBottom: '8px'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.tiktokUsername`
              })}`}
            </p>

            <div
              style={{ backgroundColor: !state.hasTiktok ? '#ddd' : 'transparent' }}
              className={styles.searchBarWrapper}>
              <input
                value={state.tiktokUserName}
                disabled={!state.hasTiktok}
                onChange={(event) =>
                  dispatch({ tiktokUserName: event.target.value.trim().toLowerCase() })
                }
                placeholder={
                  state.hasTiktok
                    ? `${intl.formatMessage({ id: `activation-form.required` })}`
                    : `${intl.formatMessage({ id: `activation-form.notRequired` })}`
                }
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', marginTop: 8 }}>
            <RadioButton htmlFor="sameEmail_2" style={{ display: 'flex' }}>
              <input
                style={{ borderRadius: 0, marginRight: 5 }}
                type="checkbox"
                id="sameEmail_2"
                checked={!state.hasTiktok}
                onChange={() => {
                  dispatch({ hasTiktok: !state.hasTiktok, tiktokUserName: '' });
                }}
              />
              <Label
                style={{
                  marginLeft: 16,
                  marginTop: 2,
                  color: '#4d4d4d'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.tiktokMsg`
                })}`}
              </Label>
              <span className="radio-checkmark" />
            </RadioButton>
          </div>
          <p
            style={{
              color: '#4D4D4D',

              fontFamily: 'Montserrat Medium',
              marginTop: '32px',
              marginBottom: '0px'
            }}>
            {`${intl.formatMessage({
              id: `activation-form.contactedMessage`
            })}`}
          </p>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', marginTop: 8 }}>
            <RadioButton htmlFor="sameEmail_3" style={{ marginBottom: '5px', display: 'flex' }}>
              <input
                style={{ borderRadius: 0, marginRight: 5 }}
                type="checkbox"
                id="sameEmail_3"
                checked={state.allowEmail}
                onChange={() => {
                  dispatch({
                    allowEmail: !state.allowEmail,
                    notificationError: !state.allowWhatsapp && state.allowEmail
                  });
                }}
              />
              <Label
                style={{
                  marginLeft: 16,
                  marginTop: 2,
                  color: '#4d4d4d'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.email`
                })}`}
              </Label>
              <span className="radio-checkmark" />
            </RadioButton>
          </div>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', marginTop: '5px' }}>
            <RadioButton htmlFor="sameEmail_4" style={{ display: 'flex' }}>
              <input
                style={{ borderRadius: 0, marginRight: 5 }}
                type="checkbox"
                id="sameEmail_4"
                checked={state.allowWhatsapp}
                onChange={() => {
                  dispatch({
                    allowWhatsapp: !state.allowWhatsapp,
                    notificationError: state.allowWhatsapp && !state.allowEmail
                  });
                }}
              />
              <Label
                style={{
                  marginLeft: 16,
                  marginTop: 2,
                  color: '#4d4d4d'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.whatsapp`
                })}`}
              </Label>
              <span className="radio-checkmark" />
            </RadioButton>
          </div>
          {state.notificationError && (
            <span
              style={{
                color: 'red',
                marginTop: 2,
                fontSize: '14px'
              }}>{`${intl.formatMessage({ id: `activation-form.notificationError` })}`}</span>
          )}
          <p
            style={{
              color: '#4D4D4D',
              fontFamily: 'Montserrat Medium',
              marginTop: '32px',
              marginBottom: '8px'
            }}>
            {`${intl.formatMessage({
              id: `activation-form.agencyMessage`
            })}`}
          </p>

          <CustomRadioContainer htmlFor="sameEmail_45" style={{ display: 'flex' }}>
            <input
              style={{ borderRadius: 0 }}
              type="radio"
              id="sameEmail_45"
              checked={state.hasAgency === 'yes'}
              onChange={() => {
                dispatch({ hasAgency: 'yes', agencyId: null });
              }}
            />
            <Label
              style={{
                marginLeft: 8,
                marginTop: 2,
                color: '#4d4d4d'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.yes`
              })}`}
            </Label>
            <span className="radio-checkmark" />
          </CustomRadioContainer>
          <CustomRadioContainer htmlFor="sameEmail_46" style={{ display: 'flex' }}>
            <input
              style={{ borderRadius: 0 }}
              type="radio"
              id="sameEmail_46"
              checked={state.hasAgency === 'no'}
              onChange={() => {
                dispatch({ hasAgency: 'no', agencyId: null });
              }}
            />
            <Label
              style={{
                marginLeft: 8,
                marginTop: 2,
                color: '#4d4d4d'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.no`
              })}`}
            </Label>
            <span className="radio-checkmark" />
          </CustomRadioContainer>
          {state.hasAgency === 'no' && (
            <div>
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.notificationEmail`
                  })}`}
                </p>

                <div className={styles.searchBarWrapper}>
                  <input
                    value={state.email}
                    onChange={(event) => {
                      const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,})+$/;

                      dispatch({
                        email: event.target.value,
                        invalidEmail: event.target.value && !event.target.value.match(validRegex)
                      });
                    }}
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.required`
                    })}`}
                  />
                </div>
              </div>
              {state.invalidEmail && (
                <span
                  style={{
                    color: 'red',
                    marginTop: 2,
                    fontSize: '14px'
                  }}>{`${state.email}${intl.formatMessage({
                  id: `activation-form.notvalidEmail`
                })}`}</span>
              )}
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.phone`
                  })}`}
                </p>
                <div className={styles.phoneSearchBarWrapper}>
                  <PhoneInput
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.required`
                    })}`}
                    country="es"
                    inputClass={styles.phoneInput}
                    value={state.phoneNumber}
                    inputStyle={{ width: '450px' }}
                    containerClass="phone-container"
                    onChange={(phone) => {
                      dispatch({ phoneNumber: phone, contactError: !validNum(phone) });
                    }}
                  />
                </div>
              </div>
              {state.contactError && (
                <span
                  style={{
                    color: 'red',
                    marginTop: 2,
                    fontSize: '14px'
                  }}>{`${intl.formatMessage({
                  id: `activation-form.contactError`
                })}`}</span>
              )}
            </div>
          )}
          {state.hasAgency === 'yes' && (
            <div style={{ marginTop: '32px' }}>
              <p
                style={{
                  color: '#4D4D4D',
                  fontFamily: 'Montserrat Medium',
                  marginBottom: '8px'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.agency`
                })}`}
              </p>
              <Select
                onChange={(event) =>
                  dispatch({
                    agencyId: event,
                    agencyEmail: null,
                    agencyPhonenumber: '',
                    agencyName: null,
                    agencyWebsite: null
                  })
                }
                // classNamePrefix="react-select"
                styles={{
                  control: (style) => ({
                    ...style,
                    height: '33px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                    '@media only screen and (max-width: 600px)': {
                      ...style['@media only screen and (max-width: 600px)'],
                      height: '48px'
                    }
                  })
                }}
                components={{
                  Option: IconOption
                }}
                placeholder={
                  <div className={styles.selectPlaceholderText}>{`${intl.formatMessage({
                    id: `activation-form.selectAgency`
                  })}`}</div>
                }
                value={state.agencyId}
                options={
                  agenciesQuery.data
                    ? [
                        {
                          label: `${intl.formatMessage({
                            id: `activation-form.noAgency`
                          })}`,
                          value: -1,
                          logo: null
                        },
                        ...agenciesQuery.data.agencies.rows.map((agency) => ({
                          label: agency.name,
                          value: agency.id,
                          logo: agency.logo
                        }))
                      ]
                    : []
                }
              />
            </div>
          )}

          {state.agencyId && state.agencyId.value === -1 && (
            <div>
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.agencyName`
                  })}`}
                </p>
                <div className={styles.searchBarWrapper}>
                  <input
                    value={state.agencyName}
                    onChange={(event) => {
                      dispatch({ agencyName: event.target.value });
                    }}
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.agencyNamePlaceHolder`
                    })}`}
                  />
                </div>
              </div>
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.agencyEmail`
                  })}`}
                </p>
                <div className={styles.searchBarWrapper}>
                  <input
                    value={state.agencyEmail}
                    onChange={(event) => {
                      const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,})+$/;
                      dispatch({
                        agencyEmail: event.target.value,
                        agencyEmailError:
                          event.target.value && !event.target.value.match(validRegex)
                      });
                    }}
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.agencyEmailPlaceHolder`
                    })}`}
                  />
                </div>
              </div>
              {state.agencyEmailError && (
                <span
                  style={{
                    color: 'red',
                    marginTop: 2
                  }}>{`${state.agencyEmail}${intl.formatMessage({
                  id: `activation-form.notvalidEmail`
                })}`}</span>
              )}
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.agencyPhone`
                  })}`}
                </p>
                <div className={styles.phoneSearchBarWrapper}>
                  <PhoneInput
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.required`
                    })}`}
                    inputClass={styles.phoneInput}
                    country="es"
                    value={state.agencyPhonenumber}
                    inputStyle={{ width: '450px' }}
                    containerClass="phone-container"
                    onChange={(phone) => {
                      dispatch({
                        agencyPhonenumber: phone,
                        agencyContactError: !validNum(phone)
                      });
                    }}
                  />
                </div>
              </div>
              {state.agencyContactError && (
                <span
                  style={{
                    color: 'red',
                    marginTop: 2,
                    fontSize: '14px'
                  }}>{`${intl.formatMessage({
                  id: `activation-form.contactError`
                })}`}</span>
              )}
              <div style={{ marginTop: '32px' }}>
                <p
                  style={{
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat Medium',
                    marginBottom: '8px'
                  }}>
                  {`${intl.formatMessage({
                    id: `activation-form.agencyWebsite`
                  })}`}
                </p>
                <div className={styles.searchBarWrapper}>
                  <input
                    value={state.agencyWebsite}
                    onChange={(event) => {
                      dispatch({ agencyWebsite: event.target.value });
                    }}
                    placeholder={`${intl.formatMessage({
                      id: `activation-form.agencyWebsitePlaceHolder`
                    })}`}
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{ marginTop: '32px' }}>
            <p
              style={{
                color: '#4D4D4D',
                fontFamily: 'Montserrat Medium',
                marginBottom: '8px'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.options`
              })}`}
            </p>
            {showCategoriesList()}
          </div>
          <div style={{ marginTop: '32px' }}>
            <p
              style={{
                color: '#4D4D4D',
                fontFamily: 'Montserrat Medium',
                marginBottom: '8px'
              }}>
              {`${intl.formatMessage({
                id: `activation-form.exchange-campaigns`
              })}`}
            </p>
            <RadioButton htmlFor="nogift" style={{ marginBottom: '5px', display: 'flex' }}>
              <input
                style={{ borderRadius: 0, marginRight: 5 }}
                type="checkbox"
                id="nogift"
                checked={state.noGift}
                onChange={() => {
                  dispatch({
                    noGift: !state.noGift
                  });
                }}
              />
              <Label
                style={{
                  marginLeft: 16,
                  marginTop: 2,
                  color: '#4d4d4d'
                }}>
                {`${intl.formatMessage({
                  id: `activation-form.radio-product-only`
                })}`}
              </Label>
              <span className="radio-checkmark" />
            </RadioButton>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'center',
              marginTop: '32px'
            }}>
            <Button
              primary
              disabled={!state.hasInstagram && !state.hasTiktok}
              className="modal-accept-button"
              onClick={() => {
                if (
                  state.contactError ||
                  state.agencyEmailError ||
                  state.agencyContactError ||
                  state.invalidEmail ||
                  state.notificationError ||
                  (!state.instagramUserName && state.hasInstagram) ||
                  (!state.tiktokUserName && state.hasTiktok) ||
                  (!state.hasInstagram && !state.hasTiktok) ||
                  state.hasAgency === null ||
                  (state.hasAgency === 'no' && (!state.email || !state.phoneNumber)) ||
                  (state.hasAgency === 'yes' && !state.agencyId) ||
                  (state.hasAgency === 'yes' &&
                    state.agencyId.value === -1 &&
                    (!state.agencyEmail || !state.agencyName || !state.agencyPhonenumber))
                ) {
                  dispatch({ dataRequiredError: true });
                } else {
                  saveUserData();
                }
              }}>
              {`${intl.formatMessage({
                id: `activation-form.submit`
              })}`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ActivationPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  language: PropTypes.string
};

ActivationPage.defaultProps = {
  language: 'en'
};

export default ActivationPage;
