import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Label } from './style';

const Checkbox = ({ key, isDisabled, checked, callback, label }) => (
  <RadioButton htmlFor={key} style={{ marginBottom: '8px', display: 'flex' }}>
    <input
      disabled={!checked && isDisabled}
      style={{ borderRadius: 0, marginRight: 5 }}
      type="checkbox"
      id={key}
      checked={checked}
      onChange={() => {
        callback();
      }}
    />
    <Label
      style={{
        marginLeft: 16,
        marginTop: 2,
        color: '#4d4d4d'
      }}>
      {label}
    </Label>
    <span className="radio-checkmark" />
  </RadioButton>
);

Checkbox.propTypes = {
  key: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
export default Checkbox;
