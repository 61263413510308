/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';

import dropDown from '../../images/dropdown-caret.png';
import Checkbox from './checkbox-field';

const CategoryWrapper = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  font-size: 20px;
  height: auto;
  background: #ffffff;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  border-radius: 8px;
  margin-bottom: 8px;
  @media (max-width: 600px) {
    margin-bottom: 16px;
    padding: 14px 16px;
    font-size: 16px;
  }
`;

const SubCategoryWrapper = styled(CategoryWrapper)`
  height: unset;
  padding: 8px;
  ${(scProps) => {
    if (scProps.showCategory) {
      return css`
        display: grid;
        grid-template-columns: auto;
        column-gap: 10px;
        align-items: flex-start;

        @media (max-width: 500px) {
          grid-template-columns: auto;
        }
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const CategoryTitle = styled.p`
  width: 100%;
  cursor: pointer;
  font-family: 'Montserrat Regular';
  margin: 0;
  text-align: left;
  color: #4d4d4d;

  span {
    font-size: 14px;
    white-space: nowrap;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

const CategoryList = ({
  lang,
  category,
  subCategories,
  categoryId,
  setData,
  allCheckSubCategories
}) => {
  const [showCategory, setShowCategory] = useState(false);

  const handleSubCategoryChange = (id) => {
    let subCategory = [];
    const data = [...allCheckSubCategories];
    // eslint-disable-next-line no-unused-expressions
    allCheckSubCategories.includes(id)
      ? (subCategory = data.filter((x) => x !== id))
      : (subCategory = [...allCheckSubCategories, id]);
    setData(subCategory);
  };

  return (
    <>
      <CategoryWrapper>
        <CategoryTitle onClick={() => setShowCategory(!showCategory)}>
          <span>{category}</span>
        </CategoryTitle>
        <img
          src={dropDown}
          aria-hidden="true"
          alt={showCategory ? 'close' : 'open'}
          style={{
            width: 18,
            cursor: 'pointer',
            transform: showCategory ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
          onClick={() => setShowCategory(!showCategory)}
        />
      </CategoryWrapper>
      <SubCategoryWrapper showCategory={showCategory && subCategories && subCategories.length > 0}>
        {showCategory &&
          subCategories &&
          subCategories.length > 0 &&
          subCategories.map((item) => (
            <div key={item.name}>
              <Checkbox
                callback={() => {
                  handleSubCategoryChange(item.id);
                }}
                checked={allCheckSubCategories.includes(item.id)}
                name="subCategoryIds"
                key={`${categoryId}_${item.id}`}
                label={lang === 'en' ? item.name : item.nameES}
                value={item.id}
                isDisabled={allCheckSubCategories.length >= 5}
              />
            </div>
          ))}
      </SubCategoryWrapper>
    </>
  );
};

export default CategoryList;
